@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  margin: 0px;
  padding: 0px;
} */
/* 
.container {
  justify-items: center;
} */
